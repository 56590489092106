import { Li } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { SectionSubtitle, SectionText } from '@playbooks/interface/sections';
import { Skeleton } from 'molecules/skeletons';

export const InfoItem = ({ keyName, loading, icon, value, tailwind }) => {
	// Render
	return (
		<Li border='border-b' display='flex-start-top' space='space-x-4' spacing='py-4' {...tailwind?.li}>
			<FadIcon icon={icon} color='gray-600 dark:gray-500' fontSize='text-xl' {...tailwind?.icon} />
			{keyName && <SectionSubtitle fontSize='text-sm'>{keyName}</SectionSubtitle>}
			{loading ? (
				<Skeleton className='w-20' />
			) : (
				<SectionText align='text-left' fontSize='text-sm' opacity='opacity-75'>
					{value}
				</SectionText>
			)}
		</Li>
	);
};
