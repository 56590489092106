import { Card, CardBody, CardFooter, CardHeader } from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { SkeletonWrapper } from 'components/wrappers/skeleton-wrapper';

const DisplaySkeleton = ({ tailwind }) => {
	// Render
	return (
		<Card bgColor='' display='flex-column' spacing='' {...tailwind?.card}>
			<CardHeader
				aspect='aspect-[1/1]'
				borderRadius='rounded-md'
				display='flex-between'
				overflow='overflow-hidden'
				space='space-x-4'
				spacing='m-4 p-2'
				width='w-20'
				{...tailwind?.header}>
				<SkeletonWrapper className='aspect-[1/1]' />
			</CardHeader>
			<CardBody flex='grow' space='space-y-2' spacing='px-4 pb-4' {...tailwind?.body}>
				<h5>
					<SkeletonWrapper width='50%' />
				</h5>
				<h6>
					<SkeletonWrapper width='75%' />
				</h6>
				<h6>
					<SkeletonWrapper width='75%' />
				</h6>
			</CardBody>
			<CardFooter display='flex-between' space='space-x-4' {...tailwind?.footer}>
				<Span width='w-24'>
					<SkeletonWrapper />
				</Span>
				<Span width='w-24'>
					<SkeletonWrapper />
				</Span>
			</CardFooter>
		</Card>
	);
};

export { DisplaySkeleton };
