import { Badge } from '@playbooks/interface/badges';
import { BtnWrapper } from '@playbooks/interface/buttons';
import {
	Card,
	CardBody,
	CardHeader,
	CardIcon,
	CardImg,
	CardSubtitle,
	CardText,
	CardTitle,
} from '@playbooks/interface/cards';
import { Span } from '@playbooks/interface/html';
import { LinkWrapper } from '@playbooks/interface/links';
import { capitalize } from 'utils';

export const SearchCard = ({
	modelName,
	photo,
	icon = 'magnifying-glass',
	badges,
	title,
	subtitle,
	text,
	tailwind,
}) => {
	// Render
	return (
		<Card
			border='border-b'
			borderRadius=''
			display='flex-between'
			hover='group-hover:bg-gray-100 group-hover:dark:bg-gray-800'
			space='space-x-8'
			spacing='p-4'
			{...tailwind?.card}>
			<Span display='flex-start' space='space-x-4'>
				<CardHeader
					aspect='aspect-[1/1]'
					borderRadius='rounded-md'
					display='flex-middle'
					flex='shrink-0'
					overflow='overflow-hidden'
					spacing=''
					width='w-10'
					{...tailwind?.header}>
					{photo ? <CardImg src={photo} {...tailwind?.photo} /> : <CardIcon icon={icon} {...tailwind?.icon} />}
				</CardHeader>
				<CardBody spacing=''>
					{title && (
						<CardTitle fontSize='text-base'>
							<Span>{title}</Span>
							{badges}
						</CardTitle>
					)}
					{subtitle && <CardSubtitle className='line-clamp-2'>{subtitle}</CardSubtitle>}
					{text && <CardText>{text}</CardText>}
				</CardBody>
			</Span>
			{modelName && (
				<Badge
					bgColor=''
					border='border'
					borderColor='border-gray-400 dark:border-gray-500'
					color='gray-600 dark:gray-400'>
					{capitalize(modelName)}
				</Badge>
			)}
		</Card>
	);
};

export const SearchBtnCard = props => (
	<BtnWrapper onClick={props.onClick} cursor='cursor-pointer' group='group' width='w-full'>
		<SearchCard {...props} />
	</BtnWrapper>
);

export const SearchLinkCard = props => (
	<LinkWrapper href={props.href} group='group'>
		<SearchCard border='border' spacing='p-4' {...props} />
	</LinkWrapper>
);
